import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import mixpanelEvents from 'constants/mixpanel';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { Column } from 'destiny/dist/types/table';
import { BENE_FORM_STEPS } from 'modules/beneficiary-v2/beneficiary.context';
import BeneficiaryBankAccountCurrencyColumn from 'modules/beneficiary-v2/BeneficiaryBankAccountCurrencyColumn';
import BeneficiaryBankAccountPaymentTypeColumn from 'modules/beneficiary-v2/BeneficiaryBankAccountPaymentTypeColumn';
import BeneficiaryBankAccountWalletActionColumn from 'modules/beneficiary-v2/BeneficiaryBankAccountWalletActionColumn';
import BeneficiaryBankAccountWalletNumberColumn from 'modules/beneficiary-v2/BeneficiaryBankAccountWalletNumberColumn';
import BeneficiaryWalletCurrencyColumn from 'modules/beneficiary-v2/BeneficiaryWalletCurrencyColumn';
import { MenuItem } from 'types';
import { ActionTypes } from 'types/beneficiary';
import { CURRENCY_TYPE } from 'types/beneficiary-v2';

export const COUNTER_PARTIES_ACTIONS: MenuItem[] = [
  {
    label: 'Edit',
    value: ActionTypes.EDIT,
    color: COLORS.TEXT_PRIMARY,
    iconId: 'edit-02',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
    id: mixpanelEvents.COUNTER_PARTY_EDIT_CLICK,
    permissionId: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    scope: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
  },
  {
    label: 'Send money',
    value: ActionTypes.SEND_MONEY,
    color: COLORS.TEXT_PRIMARY,
    iconId: 'arrow-up-right',
    iconCategory: ICON_SPRITE_TYPES.ARROWS,
    id: mixpanelEvents.COUNTER_PARTY_SEND_MONEY_CLICK,
    permissionId: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId,
    scope: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
  },
  {
    label: 'Remove recipient',
    value: ActionTypes.DELETE,
    color: COLORS.TEXT_PRIMARY,
    iconId: 'trash-02',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
    id: mixpanelEvents.COUNTER_PARTY_DELETE_CLICK,
    permissionId: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    scope: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
  },
];

export const BANK_ACCOUNTS_COLUMNS: Column[] = [
  {
    label: '',
    id: 'beneficiary_alias',
    keyNames: ['beneficiary_alias'],
    className: 'f-14-300 tw-py-6 !tw-pl-5 tw-w-[250px]',
  },
  {
    label: '',
    id: 'destination_currency',
    keyNames: ['destination_currency'],
    className: 'tw-text-TEXT_SECONDARY f-14-300 tw-py-5 tw-w-60',
    Component: BeneficiaryBankAccountCurrencyColumn,
  },
  {
    label: '',
    id: 'beneficiary_account_number',
    keyNames: ['beneficiary_account_number', 'payout_method'],
    className: 'tw-text-TEXT_SECONDARY tw-py-5',
    Component: BeneficiaryBankAccountWalletNumberColumn,
  },
  {
    label: '',
    id: 'routing_code_type_1',
    keyNames: ['*'],
    className: 'tw-py-5 f-12-400',
    Component: BeneficiaryBankAccountPaymentTypeColumn,
  },
  {
    label: '',
    id: 'action',
    keyNames: ['*'],
    className: 'tw-py-5',
    Component: BeneficiaryBankAccountWalletActionColumn,
  },
];

export const BANK_ACCOUNT_ACTIONS: MenuItem[] = [
  {
    label: 'Edit',
    value: ActionTypes.EDIT,
    color: COLORS.TEXT_PRIMARY,
    iconId: 'edit-02',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
    id: mixpanelEvents.BENEFICIARY_EDIT_CLICK,
    permissionId: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    scope: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
  },
  {
    label: 'Send money',
    value: ActionTypes.SEND_MONEY,
    color: COLORS.TEXT_PRIMARY,
    iconId: 'arrow-up-right',
    iconCategory: ICON_SPRITE_TYPES.ARROWS,
    id: mixpanelEvents.BENEFICIARY_SEND_MONEY_CLICK,
    permissionId: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId,
    scope: FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope,
  },
  {
    label: 'Remove account',
    value: ActionTypes.DELETE,
    color: COLORS.TEXT_PRIMARY,
    iconId: 'trash-02',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
    id: mixpanelEvents.BENEFICIARY_DELETE_CLICK,
    permissionId: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    scope: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
  },
];

export const WALLETS_COLUMNS: Column[] = [
  {
    label: '',
    id: 'beneficiary_alias',
    keyNames: ['beneficiary_alias'],
    className: 'f-14-300 tw-py-6 !tw-pl-5 tw-w-[250px]',
  },
  {
    label: '',
    id: 'routing_code_value_1',
    keyNames: ['routing_code_value_1'],
    className: 'tw-text-TEXT_SECONDARY f-14-300 tw-py-5 tw-w-60',
    Component: BeneficiaryWalletCurrencyColumn,
  },
  {
    label: '',
    id: 'beneficiary_account_number',
    keyNames: ['beneficiary_account_number', 'payout_method'],
    className: 'tw-text-TEXT_SECONDARY tw-py-5',
    Component: BeneficiaryBankAccountWalletNumberColumn,
  },
  {
    label: '',
    id: 'action',
    keyNames: ['*'],
    className: 'tw-py-5',
    Component: BeneficiaryBankAccountWalletActionColumn,
  },
];

export const COUNTER_PARTIES_PAGE_LIMIT = 100;

export const FORM_HEADINGS = {
  [BENE_FORM_STEPS.STEP_2]: 'Add new recipient',
  [BENE_FORM_STEPS.STEP_1]: 'Add account/wallet',
};

export const FORM_HEADINGS_PAYMENT_METHOD = {
  [CURRENCY_TYPE.FIAT]: 'Add new account',
  [CURRENCY_TYPE.CRYPTO]: 'Add new wallet',
};

export const FORM_HEADINGS_PAYMENT_METHOD_SUBMIT_BUTTON = {
  [CURRENCY_TYPE.FIAT]: 'Add account',
  [CURRENCY_TYPE.CRYPTO]: 'Add wallet',
};

export const BENE_FORM_KEYS = {
  PAYOUT_METHOD: 'payout_method',
  TRANSFER_TYPE: 'transfer_type',
  ROUTING_CODE_TYPE_1: 'routing_code_type_1',
  LOCAL: 'LOCAL',
  IMPS: 'IMPS',
  INR: 'INR',
  IFSC: 'IFSC',
  SWIFT: 'SWIFT',
  SEPA: 'SEPA',
  EUR: 'EUR',
  ROUTING_CODE_VALUE_1: 'routing_code_value_1',
  ROUTING_CODE_VALUE_1_SWIFT: 'routing_code_value_1_swift',
  FEDWIRE: 'WIRE',
  ROUTING_CODE_VALUE_1_WIRE: 'routing_code_value_1_wire',
  DESTINATION_CURRENCY: 'destination_currency',
  USD: 'USD',
  BENEFICIARY_ACCOUNT_NUMBER: 'beneficiary_account_number',
  BENEFICIARY_BANK_TYPE: 'beneficiary_bank_type',
  BENEFICIARY_COUNTRY_CODE: 'beneficiary_country_code',
  BENEFICIARY_STATE: 'beneficiary_state',
  BENEFICIARY_BANK_COUNTRY_CODE: 'beneficiary_bank_country_code',
  DESTINATION_COUNTRY: 'destination_country',
  BENEFICIARY_BANK_STATE: 'beneficiary_bank_state',
  CORRESPONDANT_BANK_COUNTRY_CODE: 'correspondent_bank_country_code',
  CORRESPONDANT_BANK_STATE: 'correspondent_bank_state',
  IS_CORRESPONDANT_BANK_DETAILS_AVAILABLE: 'is_correspondent_bank_details_available',
  COUNTRY: 'country',
  STATE: 'state',
  BENEFICIARY_CONTACT_NUMBER: 'beneficiary_contact_number',
  BENEFICIARY_CONTACT_COUNTRY_CODE: 'beneficiary_contact_country_code',
  BENEFICIARY_ACCOUNT_NUMBER_IBAN_SWIFT: 'beneficiary_account_number_iban_swift',
  BENEFICIARY_ACCOUNT_NUMBER_IBAN_EUR: 'beneficiary_account_number_iban_eur',
  BENEFICIARY_ACCOUNT_NUMBER_INR: 'beneficiary_account_number_inr',
  BENEFICIARY_ACCOUNT_TYPE: 'beneficiary_account_type',
  BENEFICIARY_RELATIONSHIP: 'beneficiary_relationship',
  BENEFICIARY_ADDRESS: 'beneficiary_address',
  BENEFICIARY_CITY: 'beneficiary_city',
  BENEFICIARY_EMAIL: 'beneficiary_email',
  BENEFICIARY_NAME: 'beneficiary_name',
  BENEFICIARY_POSTCODE: 'beneficiary_postcode',
  COUNTER_PARTY_ALIAS: 'counter_party_alias',
  IS_COUNTER_PARTY_ELIGIBLE: 'is_counter_party_eligible',
  NICK_NAME: 'nick_name',
  EMAIL: 'email',
  NAME: 'name',
  TYPE: 'type',
  RELATIONSHIP: 'relationship',
  COUNTRY_CODE: 'country_code',
  ADDRESS: 'address',
  CITY: 'city',
  POSTCODE: 'postcode',
  CONTACT_NUMBER: 'contact_number',
  CONTACT_COUNTRY_CODE: 'contact_country_code',
  BENEFICIARY_FIRST_NAME: 'beneficiary_first_name',
  BENEFICIARY_LAST_NAME: 'beneficiary_last_name',
  INDIVIDUAL: 'Individual',
  AED_LOCAL: 'AED LOCAL',
};

export const BENEFICIARY_FORM_ERROR_MAPPING: Record<string, { step: BENE_FORM_STEPS; keys: string[] }> = {
  BENE_STREET_ADDRESS_INVALID: { step: BENE_FORM_STEPS.STEP_2, keys: ['beneficiary_address'] },
  BENE_CITY_INVALID: { step: BENE_FORM_STEPS.STEP_2, keys: ['beneficiary_city'] },
  BENE_EMAIL_INVALID: { step: BENE_FORM_STEPS.STEP_2, keys: ['beneficiary_email'] },
  BENE_POST_CODE_INVALID: { step: BENE_FORM_STEPS.STEP_2, keys: ['beneficiary_postcode'] },

  ROUTING_NUMBER_INCORRECT: {
    step: BENE_FORM_STEPS.STEP_3,
    keys: ['routing_code_value_1_wire', 'routing_code_value_1'],
  },
  SWIFT_CODE_INVALID: { step: BENE_FORM_STEPS.STEP_3, keys: ['routing_code_value_1_swift'] },
  BANK_STREET_ADDRESS_INVALID: { step: BENE_FORM_STEPS.STEP_3, keys: ['beneficiary_bank_address'] },
  BANK_POST_CODE_INVALID: { step: BENE_FORM_STEPS.STEP_3, keys: ['beneficiary_bank_postcode'] },
  BANK_NAME_INVALID: { step: BENE_FORM_STEPS.STEP_3, keys: ['beneficiary_bank_name'] },
  BENE_BANK_CITY_INVALID: { step: BENE_FORM_STEPS.STEP_3, keys: ['beneficiary_bank_city'] },
  INVALID_WALLET_ADDRESS: { step: BENE_FORM_STEPS.STEP_3, keys: ['beneficiary_account_number'] },
};
