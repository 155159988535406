import { FC } from 'react';
import { THIRD_PARTY_PAYOUTS_STEPS_TYPES as STEPS_TYPES } from 'constants/beneficiary';
import { ACCESS_FLAGS } from 'constants/config';
import { defaultFn } from 'destiny/dist/constants/index';
import { useAppSelector } from 'hooks/toolkit';
import AdditionalBeneDetails from 'modules/single-transfer/thirdPartyPayouts/steps/AdditionalBeneDetails';
import ReviewAndPay from 'modules/single-transfer/thirdPartyPayouts/steps/ReviewAndPayV2';
import SelectRecipientWrapper from 'modules/single-transfer/thirdPartyPayouts/steps/SelectRecipientWrapper';
import SelectSourceAccountV1 from 'modules/single-transfer/thirdPartyPayouts/steps/SelectSourceAccount';
import SelectSourceAccount from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/SelectSourceAccount';
import TransferDetails from 'modules/single-transfer/thirdPartyPayouts/steps/TransferDetails';
import { RootState } from 'store';
import { defaultFnType } from 'types';
import { BeneficiaryPayoutEligibilty } from 'types/beneficiary';
import { CounterParty, CounterPartyById } from 'types/beneficiary-v2';
import { CompletedInfoParams, FiatRFQType } from 'types/transactions';

interface StepwiseViewProps {
  selectedRecipientAccount?: any;
  selectedSourceAccount?: any;
  selectedSourceAccountData?: {
    entityId: string;
    vaultId: string;
    accountId: string;
  };
  transferDetails?: any;
  rfqData?: any;
  summaryData?: any;
  step: number;
  handleNextStep: defaultFnType;
  handlePreviousStep: defaultFnType;
  handleSuccess: (data: CompletedInfoParams) => void;
  handleError: defaultFnType;
  prefilledBeneData?: BeneficiaryPayoutEligibilty;
  setFiatQuoteData?: (data: FiatRFQType) => void;
  setSelectedCounterParty?: (counterParty?: CounterParty) => void;
  selectedCounterParty?: CounterParty;
  setSelectedCounterPartyById?: (counterParty?: CounterPartyById) => void;
  selectedCounterPartyById?: CounterPartyById;
}

const StepwiseView: FC<StepwiseViewProps> = ({
  selectedRecipientAccount = null,
  selectedSourceAccount = null,
  selectedSourceAccountData,
  transferDetails = {},
  rfqData = {},
  summaryData = {},
  step,
  handleNextStep = defaultFn,
  handlePreviousStep = defaultFn,
  handleSuccess = defaultFn,
  handleError = defaultFn,
  prefilledBeneData,
  setFiatQuoteData,
  setSelectedCounterParty,
  selectedCounterParty,
  selectedCounterPartyById,
  setSelectedCounterPartyById,
}) => {
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isBizbankingEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_BUSINESS_BANKING_ENABLED];

  switch (step) {
    case STEPS_TYPES.SELECT_RECIPIENT_ACCOUNT:
      return (
        <SelectRecipientWrapper
          setSelectedCounterParty={setSelectedCounterParty}
          selectedCounterParty={selectedCounterParty}
          onRecipientAccountSelect={handleNextStep}
          selectedCounterPartyById={selectedCounterPartyById}
          setSelectedCounterPartyById={setSelectedCounterPartyById}
        />
      );
    case STEPS_TYPES.SELECT_SOURCE_ACCOUNT:
      return isBizbankingEnabled ? (
        <SelectSourceAccount
          selectedRecipientAccount={selectedRecipientAccount}
          selectedSourceAccount={selectedSourceAccount}
          selectedSourceAccountData={selectedSourceAccountData}
          onSourceAccountSelect={handleNextStep}
        />
      ) : (
        <SelectSourceAccountV1
          selectedRecipientAccount={selectedRecipientAccount}
          selectedSourceAccount={selectedSourceAccount}
          onSourceAccountSelect={handleNextStep}
        />
      );
    case STEPS_TYPES.TRANSFER_DETAILS:
      return (
        <TransferDetails
          selectedRecipientAccount={selectedRecipientAccount}
          selectedSourceAccount={selectedSourceAccount}
          handleSubmit={handleNextStep}
          onError={handleError}
          {...transferDetails}
          {...rfqData}
        />
      );
    case STEPS_TYPES.REVIEW_AND_PAY:
      return (
        <ReviewAndPay
          selectedRecipientAccount={selectedRecipientAccount}
          selectedSourceAccount={selectedSourceAccount}
          handleBack={handlePreviousStep}
          onSuccess={handleSuccess}
          onError={handleError}
          setFiatQuoteData={setFiatQuoteData}
          recipientAccount={selectedRecipientAccount}
          {...transferDetails}
          {...summaryData}
        />
      );
    case STEPS_TYPES.ADDITIONAL_BENE_DETAILS:
      return (
        <AdditionalBeneDetails
          recipientId={transferDetails?.recipientId ?? ''}
          onNext={handleNextStep}
          onError={handleError}
          onBack={handlePreviousStep}
          prefilledBeneData={prefilledBeneData}
        />
      );

    default:
      return null;
  }
};

export default StepwiseView;
