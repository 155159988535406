import { ROUTE_KEYS, ROUTES_PATH } from 'constants/routeConfig';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { NavigationItemSchema } from 'types/config';
import { NavigationGroupType } from 'types/navigation';

// TODO: dummy data to be removed after API integration
export const navigationItemsOtc: NavigationItemSchema[] = [
  {
    key: ROUTE_KEYS.OTC_HOME,
    display_name: 'Home',
    icon: 'home',
    iconId: 'home-line',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
    route: ROUTES_PATH.OTC_HOME,
  },
  {
    key: ROUTE_KEYS.OTC_TRANSACTIONS,
    display_name: 'All Orders',
    icon: 'coins-hand',
    iconId: 'coins-hand',
    iconCategory: ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE,
    route: ROUTES_PATH.OTC_TRANSACTIONS,
  },
  {
    key: ROUTE_KEYS.OTC_ACCOUNTS,
    display_name: 'Accounts',
    icon: 'safe',
    iconId: 'safe',
    iconCategory: ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE,
    route: ROUTES_PATH.ACCOUNTS_V2,
  },
  {
    key: ROUTE_KEYS.OTC_REPORTS,
    display_name: 'Reports',
    icon: 'report',
    iconId: 'clipboard-check',
    iconCategory: ICON_SPRITE_TYPES.FILES,
    route: ROUTES_PATH.REPORTS,
  },
  {
    key: ROUTE_KEYS.OTC_RECIPIENTS,
    display_name: 'Recipients',
    icon: 'user-01',
    iconId: 'user-01',
    iconCategory: ICON_SPRITE_TYPES.USERS,
    route: ROUTES_PATH.RECIPIENTS,
  },
  {
    key: ROUTE_KEYS.DEPOSITORS,
    display_name: 'Blockchain Addresses',
    icon: '',
    iconId: 'users-up',
    iconCategory: ICON_SPRITE_TYPES.USERS,
    route: ROUTES_PATH.DEPOSITORS,
  },
];

// TODO: dummy data to be removed after API integration
export const navigationItemsBanking: NavigationItemSchema[] = [
  {
    key: ROUTE_KEYS.HOME,
    display_name: 'Home',
    icon: 'home',
    iconId: 'home-line',
    iconCategory: ICON_SPRITE_TYPES.GENERAL,
    route: ROUTES_PATH.BANKING_HOME,
  },
  {
    key: ROUTE_KEYS.PAYMENTS,
    display_name: 'Payments',
    icon: 'kite',
    iconId: 'send-01',
    iconCategory: ICON_SPRITE_TYPES.COMMUNICATION,
    route: ROUTES_PATH.PAYMENTS,
  },
  {
    key: ROUTE_KEYS.TRANSACTIONS,
    display_name: 'Transactions',
    icon: 'coins-swap-01',
    iconId: 'coins-swap-01',
    iconCategory: ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE,
    route: ROUTES_PATH.TRANSACTIONS,
  },
  {
    key: ROUTE_KEYS.ACCOUNTS_V2,
    display_name: 'Accounts',
    icon: 'safe',
    iconId: 'safe',
    iconCategory: ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE,
    route: ROUTES_PATH.ACCOUNTS_V2,
  },
  {
    key: ROUTE_KEYS.REPORTS,
    display_name: 'Reports',
    icon: 'report',
    iconId: 'clipboard-check',
    iconCategory: ICON_SPRITE_TYPES.FILES,
    route: ROUTES_PATH.REPORTS,
  },
  {
    key: ROUTE_KEYS.RECIPIENTS,
    display_name: 'Recipients',
    icon: 'user-01',
    iconId: 'user-01',
    iconCategory: ICON_SPRITE_TYPES.USERS,
    route: ROUTES_PATH.RECIPIENTS,
  },
  {
    key: ROUTE_KEYS.DEPOSITORS,
    display_name: 'Blockchain Addresses',
    icon: '',
    iconId: 'users-up',
    iconCategory: ICON_SPRITE_TYPES.USERS,
    route: ROUTES_PATH.DEPOSITORS,
  },
  // {
  //   key: ROUTE_KEYS.PAYMENT_REQUESTS,
  //   display_name: 'Payment Requests',
  //   icon: 'kite',
  //   iconId: 'send-01',
  //   iconCategory: ICON_SPRITE_TYPES.COMMUNICATION,
  //   route: ROUTES_PATH.PAYMENT_REQUESTS,
  // },
];

// TODO: dummy data to be removed after API integration
export const navigationGroups: NavigationGroupType[] = [
  {
    title: 'OTC',
    nav_items: navigationItemsOtc,
    default_nav_key: ROUTE_KEYS.OTC_HOME,
  },
  {
    title: 'USD Banking',
    nav_items: navigationItemsBanking,
    default_nav_key: ROUTE_KEYS.HOME,
  },
];
