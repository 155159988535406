import { FC, useEffect, useState } from 'react';
import { useGetVaultsForBulkPayoutsQuery } from 'api/bulk-payment';
import { ACCESS_FLAGS } from 'constants/config';
import { VAULT } from 'constants/icons';
import { NO_ACCOUNTS } from 'constants/lottie/no_accounts';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { defaultFn } from 'destiny/dist/constants';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { useAppSelector } from 'hooks/toolkit';
import useConfiguration, { CONFIG_DEFINITIONS } from 'hooks/useConfiguration';
import { CUSTOMER_PROFILE } from 'modules/accounts-mea/accounts.constants';
import AddVault from 'modules/payments-vaults/addVault';
import VaultSelectorSkeleton from 'modules/single-transfer/components/vaultSelectorSkeleton';
import SelectedAccounts from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccounts';
import AccountsByEntitySelector from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/accountsByEntitySelector/AccountsByEntitySelector';
import AccountsByVaultsByEntitySelector from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/accountsByVaultsByEntitySelector/AccountsByVaultsByEntitySelector';
import { RootState } from 'store';
import { AccountByVaultIdType } from 'types/accountsApi.types';
import { ErrorCardTypes } from 'components/banners/types';
import ZeroListData from 'components/banners/ZeroListData';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface BulkPaymentSourceAccountSelector {
  sourceCurrencyCode: string;
  selectedSourceAccount?: AccountByVaultIdType;
  onSourceAccountSelect: (data: AccountByVaultIdType) => void;
}

const BulkPaymentSourceAccountSelector: FC<BulkPaymentSourceAccountSelector> = ({
  sourceCurrencyCode,
  selectedSourceAccount = null,
  onSourceAccountSelect = defaultFn,
}) => {
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isBizBankingEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_BUSINESS_BANKING_ENABLED];

  const [showAddVault, setShowAddVault] = useState(false);
  const [addVaultLoading, setAddVaultLoading] = useState(false);
  const [showAccountsByVaultsByEntitySelector, setShowAccountsByVaultsByEntitySelector] = useState(false);
  const [showEntities, setShowEntities] = useState(false);

  const { data: sourceAccountData, isFetching, isError, refetch } = useGetVaultsForBulkPayoutsQuery(sourceCurrencyCode);
  const { checkConfigEnabled } = useConfiguration();

  const { customer_profile, entities } = sourceAccountData ?? {};
  const isLargeMerchant = customer_profile === CUSTOMER_PROFILE.LARGE_MERCHANT;

  const handleShowAddVault = () => setShowAddVault((prevValue) => !prevValue);

  const handleSourceAccountSelect = (accountData: AccountByVaultIdType) => {
    onSourceAccountSelect(accountData);
    handleShowEnties();
  };

  useEffect(() => {
    if (!selectedSourceAccount) {
      const selectedEntity = entities?.[0];
      const selectedVault = selectedEntity?.vaults?.[0];
      const selectedAccount = selectedVault?.accounts?.[0];

      selectedAccount && onSourceAccountSelect({ ...selectedAccount, parentEntity: selectedEntity });
    }
  }, [entities]);

  const handleShowAccountsByVaultsByEntitySelector = () =>
    setShowAccountsByVaultsByEntitySelector((prevView) => !prevView);

  const handleShowEnties = () => {
    setShowEntities((prevValue) => !prevValue);
    isLargeMerchant && handleShowAccountsByVaultsByEntitySelector();
  };

  return (
    <>
      <CommonWrapper
        isLoading={isFetching}
        skeletonType={SkeletonTypes.CUSTOM}
        skeleton={<VaultSelectorSkeleton />}
        isError={isError}
        errorCardType={ErrorCardTypes.API_FAIL}
        refetchFunnction={refetch}
        isNoData={!isFetching && entities?.length === 0}
        noDataBanner={
          <>
            <SelectedAccounts
              sourceAccount
              sourceProps={{
                cardProps: {
                  title: 'No accounts found',
                  iconPath: VAULT,
                  iconClassName: 'tw-mr-4 tw-grayscale',
                  titleClassName: '!tw-text-TEXT_TERTIARY !f-14-400',
                },
                cardWrapperStyleClassName: '!tw-pointer-events-none',
              }}
            />
            <div className='tw-border tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-w-full tw-h-[318px]'>
              <ZeroListData
                handleClick={handleShowAddVault}
                title=''
                subtitle="The selected beneficiary's corridor isn't supported by the accounts you have access to. Please select a different beneficiary to continue"
                preKybSubtitle='Activate your account to start transacting with Zamp'
                buttonText='Add Account'
                lottieJson={NO_ACCOUNTS}
                lottieDimensions={{ width: '168px', height: '120px' }}
                destinyButtonProps={{
                  buttonProps: {
                    btnType: BUTTON_TYPES.PRIMARY,
                    size: BUTTON_SIZE_TYPES.SMALL,
                    onClick: handleShowAddVault,
                    wrapperClass: 'tw-min-w-[172px] tw-mt-4',
                    isLoading: addVaultLoading,
                    disabled: true, // TODO: enable when new add account flow is live
                  },
                  customLeadingIcon: <SvgSpriteLoader iconCategory={ICON_SPRITE_TYPES.GENERAL} id='plus' />,
                  showLeadingIcon: true,
                }}
                showButton={checkConfigEnabled(CONFIG_DEFINITIONS.ADD_ACCOUNT) && !isBizBankingEnabled}
                wrapperClassName='tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-[318px] tw-gap-6'
                contentClassName='tw-gap-2 tw-w-[234px]'
                titleClassName='f-14-400 tw-text-TEXT_GRAY_4'
                subtitleClassName='f-14-300 tw-text-TEXT_GRAY_4 tw-text-center'
                id='BULK_PAYMENT_PAY_ADD_ACCOUNT'
              />
            </div>
          </>
        }
      >
        {selectedSourceAccount ? (
          <SelectedAccounts
            sourceAccount={selectedSourceAccount}
            sourceProps={{ isDropdown: true, labelProps: { title: 'Account' } }}
            onSourceClick={handleShowEnties}
            isBulk
          />
        ) : (
          <SelectedAccounts
            isBulk
            sourceAccount
            sourceProps={{
              cardProps: {
                title: 'Select Source Account',
                titleClassName: '!tw-text-TEXT_PRIMARY !f-14-400',
                showIcon: false,
                onClick: handleShowEnties,
              },
              labelProps: { title: 'Account' },
              cardWrapperStyleClassName: isLargeMerchant ? '' : '!tw-pointer-events-none',
            }}
          />
        )}
        {showEntities &&
          entities &&
          (isLargeMerchant ? (
            showAccountsByVaultsByEntitySelector && (
              <AccountsByVaultsByEntitySelector
                entities={entities}
                onAccountSelect={handleSourceAccountSelect}
                onClose={handleShowEnties}
              />
            )
          ) : (
            <div className='tw-border tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-overflow-y-auto tw-w-full tw-max-h-[260px]'>
              {entities?.map((entity) => (
                <AccountsByEntitySelector
                  entity={entity}
                  key={entity?.id}
                  defaultOpen
                  onAccountSelect={handleSourceAccountSelect}
                />
              ))}
            </div>
          ))}
      </CommonWrapper>

      {showAddVault && (
        <AddVault isOpen={showAddVault} handleVisibility={handleShowAddVault} setParentLoading={setAddVaultLoading} />
      )}
    </>
  );
};

export default BulkPaymentSourceAccountSelector;
