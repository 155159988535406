import { VAULT } from 'constants/icons';
import { Text } from 'destiny/dist/components/atoms/text';
import { MapAny } from 'destiny/dist/types';
import { OptionsType } from 'destiny/dist/types/dropdown';
import MaskedAccountNumber from 'modules/accounts-mea/components/MaskedAccountNumber';
import {
  SLIPPAGE_SOURCE_AMOUNT_THRESHOLD,
  SLIPPAGE_SOURCE_CURRENCIES,
} from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.constants';
import { AccountByVaultIdType } from 'types/accountsApi.types';
import { Beneficiary, CounterPartyById } from 'types/beneficiary-v2';
import { AmountInfoType, DestCurrency, SourceCurrency } from 'types/transactions';
import Currency from 'components/currency/Currency';

export const getSelectedSourceAccountCardProps = ({
  sourceAccount,
  isMeaView,
}: {
  sourceAccount: AccountByVaultIdType;
  isMeaView?: boolean;
}) => {
  return isMeaView
    ? {
        title: (
          <div className='tw-flex tw-gap-2'>
            <Text textClass='f-14-400 tw-text-TEXT_PRIMARY tw-truncate'>
              {sourceAccount?.name ?? sourceAccount?.display_name ?? ''}
            </Text>
            {sourceAccount?.masked_number && (
              <MaskedAccountNumber
                maskedAccountNumber={sourceAccount?.masked_number ?? ''}
                textClassName='f-14-400 tw-text-TEXT_PRIMARY'
              />
            )}
          </div>
        ),
        subtitle: sourceAccount?.parentEntity?.name,
        customIcon: (
          <Currency
            code={sourceAccount?.currency_code}
            showName={false}
            hideTooltip
            customIconDimensions={{ width: 30, height: 30 }}
            iconOverrideClassName='tw-mr-4'
          />
        ),
      }
    : {
        title: sourceAccount?.name,
        subtitle: sourceAccount?.entity_name,
        iconPath: VAULT,
      };
};

export const getFormattedBeneData: (beneficiaryDetails: MapAny) => OptionsType[] = (beneficiaryDetails) => {
  return [
    {
      label: 'Type of beneficiary',
      value: beneficiaryDetails?.beneficiaryAccountType,
    },
    {
      label: 'Wallet Provider',
      value: beneficiaryDetails?.beneficiaryBankName,
    },
  ];
};

export const getFilteredBeneficiaryList: (
  search: string,
  data?: CounterPartyById,
  destCurrencyCode?: string,
  networkCode?: string
) => Beneficiary[] = (search, data, destCurrencyCode, networkCode) => {
  let list = [...(data?.crypto_beneficiaries ?? []), ...(data?.fiat_beneficiaries ?? [])];

  if (destCurrencyCode) {
    list = list.filter(
      (item) => item?.destination_currency === destCurrencyCode || item?.routing_code_value_1 === networkCode
    );
  }

  if (!search) return list;

  const searchLowerCase = search.toLowerCase();

  return list.reduce((acc: Beneficiary[], item: Beneficiary) => {
    const isNicknameMatching = item?.beneficiary_alias?.toLowerCase().includes(searchLowerCase);
    const isAccNumberMatching = item?.beneficiary_account_number?.toLowerCase().includes(searchLowerCase);

    return isNicknameMatching || isAccNumberMatching ? [...acc, item] : acc;
  }, []);
};

export const showSlippage: (
  sourceCurrency?: SourceCurrency,
  destCurrency?: DestCurrency,
  amountInfo?: AmountInfoType
) => boolean = (sourceCurrency, destCurrency, amountInfo) =>
  (SLIPPAGE_SOURCE_CURRENCIES.includes(sourceCurrency?.code ?? 'USD') ||
    SLIPPAGE_SOURCE_CURRENCIES.includes(destCurrency?.code ?? 'USD')) &&
  Number(amountInfo?.sourceAmount) >= SLIPPAGE_SOURCE_AMOUNT_THRESHOLD;

export const showCounterParty = (beneList?: OptionsType[], destCurrCode?: string, network?: string) => {
  if (!destCurrCode) return true;
  if (!beneList?.length) return false;

  return beneList.filter((bene) => bene.value === destCurrCode || bene.value === network).length > 0;
};
