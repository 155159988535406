import { type FC, useEffect } from 'react';
import {
  useAddBankAccountWalletToCounterPartyMutation,
  useCreateCounterPartyMutation,
  useGetCounterPartyEligibilityQuery,
  useLazyGetCounterPartyEligibilityForDestCurrencyQuery,
  useUpdateCounterPartyMutation,
} from 'api/beneficiary-v2';
import { PaymentType, SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { Label } from 'destiny/dist/components/molecules/label';
import { BUTTON_SIZE_TYPES } from 'destiny/dist/constants';
import { defaultFnType, MapAny } from 'destiny/dist/types';
import { useSessionToken } from 'hooks/useSessionToken';
import FullPageLayout from 'layout/FullPageLayout';
import {
  BENEFICIARY_FORM_ERROR_MAPPING,
  FORM_HEADINGS,
  FORM_HEADINGS_PAYMENT_METHOD,
  FORM_HEADINGS_PAYMENT_METHOD_SUBMIT_BUTTON,
} from 'modules/beneficiary-v2/beneficiary.constants';
import {
  BENE_FORM_STEPS,
  beneficiaryActions,
  defaultConfig,
  defaultError,
  useBeneficiaryContextStore,
} from 'modules/beneficiary-v2/beneficiary.context';
import {
  checkIfErrFieldIsActive,
  formatPayload,
  formatUpdateCounterPartyPayload,
} from 'modules/beneficiary-v2/beneficiary.utils';
import BeneficiaryCreateCounterPartyForm from 'modules/beneficiary-v2/BeneficiaryCreateCounterPartyForm';
import BeneficiaryCreateCryptoForm from 'modules/beneficiary-v2/BeneficiaryCreateCryptoForm';
import BeneficiaryCreateFiatForm from 'modules/beneficiary-v2/BeneficiaryCreateFiatForm';
import BeneficiarySelectFiatCryptoForm from 'modules/beneficiary-v2/BeneficiarySelectFiatCryptoForm';
import {
  CounterParty,
  CounterPartyUpdatePayload,
  CreateCounterPartyPayload,
  CURRENCY_TYPE,
} from 'types/beneficiary-v2';
import { checkIsObjectEmpty, convertToSnakeUppercase } from 'utils/common';
import { validateForms } from 'utils/kyb';
import { toast } from 'utils/toast';
import BottomBar from 'components/kyb/Bottombar';
import CommonWrapper from 'components/wrappers/CommonWrapper';

type BeneficiaryCreateProps = {
  onClose: defaultFnType;
  counterParty?: CounterParty;
  refetchCounterParties?: defaultFnType;
};

const BeneficiaryCreate: FC<BeneficiaryCreateProps> = ({ onClose, counterParty, refetchCounterParties }) => {
  const {
    state: {
      formStateData = defaultConfig,
      formConfig = defaultConfig,
      currentStep = BENE_FORM_STEPS.STEP_1,
      formErrors = defaultError,
    },
    dispatch,
  } = useBeneficiaryContextStore();

  const [createCounterParty, { isLoading: isCreateCounterPartyLoading }] = useCreateCounterPartyMutation();
  const [addBankAccountWalletToCounterParty, { isLoading }] = useAddBankAccountWalletToCounterPartyMutation();
  const { data, isFetching, isError } = useGetCounterPartyEligibilityQuery(counterParty?.id ?? '', {
    skip: !counterParty?.id,
  });

  const [updateCounterParty, { isLoading: isUpdateLoading }] = useUpdateCounterPartyMutation();
  const [getCounterPartyEligibilityForDestCurrency, { isLoading: isCounterPartyEligibilityForDestCurrencyLoading }] =
    useLazyGetCounterPartyEligibilityForDestCurrencyQuery();

  const { sessionToken: createCounterPartySessionToken, createSessionToken: createCounterPartSessionToken } =
    useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.CREATE_COUNTERPARTY, !!counterParty?.id);

  const { sessionToken, createSessionToken } = useSessionToken(
    SESSION_CAPABILITY_CONTEXT_KEYS.CREATE_COUNTERPARTY,
    !counterParty?.id
  );

  const { sessionToken: updateCounterPartySessionToken, createSessionToken: createUpdateCounterPartySessionToken } =
    useSessionToken(SESSION_CAPABILITY_CONTEXT_KEYS.EDIT_COUNTERPARTY, !counterParty?.id);

  const handleClose = () => {
    dispatch({ type: beneficiaryActions.RESET_FORM_CONTEXT });
    onClose();
  };

  const handleError = (error: MapAny, toastPrefix: string) => {
    const errMap = BENEFICIARY_FORM_ERROR_MAPPING[error?.data?.error?.code];
    const step = errMap?.step;
    const keys = errMap?.keys;

    if (step) {
      dispatch({
        type: beneficiaryActions.SET_STEP,
        payload: { currentStep: step },
      });
      const apiErrors = keys.reduce(
        (acc, key) =>
          checkIfErrFieldIsActive(formConfig?.[step], formStateData?.[step], key) ? { ...acc, [key]: true } : acc,
        {}
      );

      dispatch({
        type: beneficiaryActions.SET_FORM_ERRORS,
        payload: { formErrors: { ...formErrors, [step]: { ...formErrors?.[step], ...apiErrors } } },
      });
    } else
      toast.error(
        error?.data?.error?.code === 'RECORD_ALREADY_EXISTS'
          ? `${toastPrefix} already exists`
          : error?.data?.error?.message
      );
  };

  const handleSubmit = () => {
    const formattedData = formatPayload(formStateData);
    const toastPrefix = formattedData?.currency_type === CURRENCY_TYPE.CRYPTO ? 'Wallet' : 'Bank Account';

    if (counterParty?.id) {
      const payload: CreateCounterPartyPayload = {
        data: formattedData,
        counterPartyId: counterParty?.id,
        idempotencyHeader: sessionToken ?? '',
      };

      addBankAccountWalletToCounterParty(payload)
        .unwrap()
        .then(() => {
          toast.success(`${toastPrefix} added successfully to recipient ${counterParty?.name}`);
          dispatch({ type: beneficiaryActions.SET_RE_RENDER_LIST_COUNTER });
          handleClose();
        })
        .catch((error) => {
          handleError(error, toastPrefix);
          createSessionToken();
        });
    } else {
      const payload: CreateCounterPartyPayload = {
        data: formattedData,
        idempotencyHeader: createCounterPartySessionToken ?? '',
      };

      createCounterParty(payload)
        .unwrap()
        .then(() => {
          toast.success('Recipient added successfully');
          dispatch({ type: beneficiaryActions.SET_RE_RENDER_LIST_COUNTER });
          handleClose();
          refetchCounterParties?.();
        })
        .catch((error) => {
          handleError(error, toastPrefix);
          createCounterPartSessionToken();
        });
    }
  };

  const handleUpdateCounterParty = () => {
    const payload: CounterPartyUpdatePayload = {
      id: counterParty?.id ?? '',
      data: formatUpdateCounterPartyPayload(formStateData, data),
      idempotencyHeader: updateCounterPartySessionToken ?? '',
    };

    updateCounterParty(payload)
      .unwrap()
      .then(() => {
        dispatch({
          type: beneficiaryActions.SET_STEP,
          payload: { currentStep: BENE_FORM_STEPS.STEP_3 },
        });
        dispatch({
          type: beneficiaryActions.SET_FORM_STATE_DATA,
          payload: {
            formStateData: {
              ...formStateData,
              [BENE_FORM_STEPS.STEP_2]: [{ beneficiary_account_type: { value: counterParty?.type } }],
            },
          },
        });
      })
      .catch(() => {
        createUpdateCounterPartySessionToken();
      });
  };

  const checkCounterPartyEligibiltyForDestCurrency = () => {
    getCounterPartyEligibilityForDestCurrency({
      id: counterParty?.id ?? '',
      data: {
        destination_currency: formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.destination_currency?.value,
        destination_currency_type:
          formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.currency_type?.value === CURRENCY_TYPE.FIAT
            ? PaymentType.FIAT
            : PaymentType.CRYPTO,
      },
    })
      .unwrap()
      .then((res) => {
        if (res)
          dispatch({
            type: beneficiaryActions.SET_STEP,
            payload: { currentStep: BENE_FORM_STEPS.STEP_3 },
          });
        else
          dispatch({
            type: beneficiaryActions.SET_ELIGIBILTY_ERROR,
            payload: {
              eligibilityError: `${
                formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.currency_type?.value === CURRENCY_TYPE.FIAT
                  ? 'AED'
                  : 'Digital Wallet'
              } is not supported for this recipient`,
            },
          });
      });
  };

  const handleNext = () => {
    let errors = {};

    Object.keys(formErrors?.[currentStep] ?? {})?.forEach((key) => {
      if (formErrors?.[currentStep]?.[key]) {
        errors = { ...errors, [key]: true };
      }
    });

    formConfig?.[currentStep]?.forEach((item, index) => {
      const errs = validateForms(item, formStateData?.[currentStep]?.[index], formStateData?.[currentStep]);

      errors = { ...errors, ...errs };
    });

    if (checkIsObjectEmpty(errors)) {
      if (currentStep === BENE_FORM_STEPS.STEP_3) {
        handleSubmit();
      } else if (currentStep === BENE_FORM_STEPS.STEP_1 && data?.is_counter_party_eligible) {
        checkCounterPartyEligibiltyForDestCurrency();
      } else if (currentStep === BENE_FORM_STEPS.STEP_2 && data) {
        handleUpdateCounterParty();
      } else
        dispatch({
          type: beneficiaryActions.SET_STEP,
          payload: { currentStep: currentStep + 1 },
        });
    } else
      dispatch({
        type: beneficiaryActions.SET_FORM_ERRORS,
        payload: { formErrors: { ...formErrors, [currentStep]: errors } },
      });
  };

  const handleBack = () => {
    if (currentStep === BENE_FORM_STEPS.STEP_3 && data)
      dispatch({
        type: beneficiaryActions.SET_STEP,
        payload: { currentStep: BENE_FORM_STEPS.STEP_1 },
      });
    else
      dispatch({
        type: beneficiaryActions.SET_STEP,
        payload: { currentStep: currentStep - 1 },
      });
  };

  const getComponent = () => {
    switch (currentStep) {
      case BENE_FORM_STEPS.STEP_2:
        return <BeneficiaryCreateCounterPartyForm prefilledData={data} />;
      case BENE_FORM_STEPS.STEP_1:
        return <BeneficiarySelectFiatCryptoForm isAddToExistingCounterParty={!!counterParty?.id} />;
      case BENE_FORM_STEPS.STEP_3:
        return (
          <>
            {formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.currency_type?.value === CURRENCY_TYPE.FIAT ? (
              <BeneficiaryCreateFiatForm />
            ) : (
              <BeneficiaryCreateCryptoForm />
            )}
          </>
        );
      default:
        return <BeneficiaryCreateCounterPartyForm prefilledData={data} />;
    }
  };

  const getFormHeading = () =>
    currentStep === BENE_FORM_STEPS.STEP_3
      ? FORM_HEADINGS_PAYMENT_METHOD[
          formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.currency_type?.value as CURRENCY_TYPE
        ]
      : currentStep === BENE_FORM_STEPS.STEP_2 && data?.is_counter_party_eligible === false
      ? 'Additional recipient information required'
      : FORM_HEADINGS[currentStep];

  useEffect(() => {
    if (counterParty?.id && data?.is_counter_party_eligible) {
      dispatch({
        type: beneficiaryActions.SET_STEP,
        payload: { currentStep: BENE_FORM_STEPS.STEP_1 },
      });
      dispatch({
        type: beneficiaryActions.SET_FORM_STATE_DATA,
        payload: {
          formStateData: {
            ...formStateData,
            [BENE_FORM_STEPS.STEP_2]: [{ beneficiary_account_type: { value: counterParty?.type } }],
          },
        },
      });
    }
  }, [counterParty?.id, data]);

  return (
    <FullPageLayout
      id='BENE_CREATE'
      onClose={handleClose}
      layoutWidthOverrideClassName='tw-w-[724px]'
      bottomBar={
        <BottomBar
          onNext={handleNext}
          onBack={handleBack}
          nextBtnText={
            currentStep === BENE_FORM_STEPS.STEP_3
              ? FORM_HEADINGS_PAYMENT_METHOD_SUBMIT_BUTTON[
                  formStateData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.currency_type?.value as CURRENCY_TYPE
                ]
              : 'Next'
          }
          backButtonProps={{ size: BUTTON_SIZE_TYPES.MEDIUM }}
          nextButtonProps={{ size: BUTTON_SIZE_TYPES.MEDIUM }}
          wrapperClassName='!tw-h-[82px] tw-flex tw-justify-center'
          className='tw-w-[720px]'
          hideBackButton={
            currentStep === BENE_FORM_STEPS.STEP_1 || (currentStep === BENE_FORM_STEPS.STEP_2 && !!counterParty)
          }
          isLoading={
            isLoading ||
            isCreateCounterPartyLoading ||
            isUpdateLoading ||
            isCounterPartyEligibilityForDestCurrencyLoading
          }
          backButtonId={`BENEFICIARY_CREATE_BACK_BUTTON_${convertToSnakeUppercase(
            getFormHeading()?.replace('/', '_')
          )}`}
          nextButtonId={`BENEFICIARY_CREATE_NEXT_BUTTON_${convertToSnakeUppercase(
            getFormHeading()?.replace('/', '_')
          )}`}
        />
      }
      contentWrapperClassName='!tw-h-[calc(100vh-164px)] !tw-mt-20'
    >
      <CommonWrapper isLoading={isFetching} isError={isError}>
        <Label
          title={getFormHeading()}
          titleClass='f-18-500 tw-mb-2'
          description={
            <div className='f-13-500 tw-mb-6'>
              <span className='tw-text-ZAMP_PRIMARY' data-testid='single-transfer-step-count'>{`STEP 0${Math.floor(
                currentStep + 1
              )}`}</span>
              <span className='tw-text-TEXT_TERTIARY' data-testid='single-transfer-total-steps'>{` / 03`}</span>
            </div>
          }
        />

        {getComponent()}
      </CommonWrapper>
    </FullPageLayout>
  );
};

export default BeneficiaryCreate;
