import { COUNTRY_KEY, STATE_KEY } from 'constants/kyb';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { MapAny } from 'destiny/dist/types';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { BENE_FORM_KEYS } from 'modules/beneficiary-v2/beneficiary.constants';
import { BENE_FORM_STEPS } from 'modules/beneficiary-v2/beneficiary.context';
import {
  BANK_TYPES_FOR_RFI,
  ROUTING_CODE_DISPLAY_VALUE,
} from 'modules/single-transfer/thirdPartyPayouts/thirdPartyPayouts.constants';
import {
  Beneficiary,
  BeneForm,
  CounterPartyEligibility,
  CounterPartyUpdatePayloadData,
  CURRENCY_TYPE,
} from 'types/beneficiary-v2';
import { FieldType } from 'types/kyb';
import { PaymentMethod } from 'types/transactions';
import { checkActiveIfValid } from 'utils/common';
import { COUNTRY_OPTIONS } from 'components/country-picker/countries';
import Wallet from 'components/wallet-item/Wallet';

export const formatPayload = (formData: Record<BENE_FORM_STEPS, MapAny[]>) => {
  const payload: MapAny = {};

  Object.values(formData)?.forEach((step: MapAny[]) => {
    step?.forEach((item) => {
      Object.keys(item)?.forEach((key) => {
        if (!item?.[key]) return;
        if (typeof item?.[key] === 'object') payload[key] = item?.[key]?.value;
        else payload[key] = item?.[key];
      });
    });
  });

  if (formData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.currency_type?.value === CURRENCY_TYPE.FIAT) {
    payload[BENE_FORM_KEYS.PAYOUT_METHOD] = PaymentMethod.FIAT;
    payload[BENE_FORM_KEYS.TRANSFER_TYPE] = BENE_FORM_KEYS.LOCAL;

    if (formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_type_1?.value === BENE_FORM_KEYS.IMPS) {
      payload[BENE_FORM_KEYS.DESTINATION_CURRENCY] = BENE_FORM_KEYS.INR;
      payload[BENE_FORM_KEYS.ROUTING_CODE_TYPE_1] = BENE_FORM_KEYS.IFSC;
      payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER] =
        formData?.[BENE_FORM_STEPS.STEP_3]?.[1].beneficiary_account_number_inr;
      !!payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER_INR] &&
        delete payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER_INR];
    } else if (formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_type_1?.value === BENE_FORM_KEYS.SEPA) {
      payload[BENE_FORM_KEYS.DESTINATION_CURRENCY] = BENE_FORM_KEYS.EUR;
      payload[BENE_FORM_KEYS.ROUTING_CODE_TYPE_1] = BENE_FORM_KEYS.SWIFT;
      payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1] =
        formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_value_1_swift;
      payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER] =
        formData?.[BENE_FORM_STEPS.STEP_3]?.[1].beneficiary_account_number_iban_eur;
      !!payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1_SWIFT] && delete payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1_SWIFT];
      !!payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER_IBAN_EUR] &&
        delete payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER_IBAN_EUR];
    } else if (formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_type_1?.value === BENE_FORM_KEYS.FEDWIRE) {
      payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1] = formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_value_1_wire;
      delete payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1_WIRE];
    } else if (formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_type_1?.value === BENE_FORM_KEYS.SWIFT) {
      payload[BENE_FORM_KEYS.DESTINATION_CURRENCY] = BENE_FORM_KEYS.USD;
      payload[BENE_FORM_KEYS.TRANSFER_TYPE] = BENE_FORM_KEYS.SWIFT;
      payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1] =
        formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_value_1_swift;
      payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER] =
        formData?.[BENE_FORM_STEPS.STEP_3]?.[1].beneficiary_account_number_iban_swift;
      !!payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER_IBAN_SWIFT] &&
        delete payload[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_NUMBER_IBAN_SWIFT];
      !!payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1_SWIFT] && delete payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1_SWIFT];
    } else if (formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_type_1?.value === BENE_FORM_KEYS.AED_LOCAL) {
      payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1] =
        formData?.[BENE_FORM_STEPS.STEP_3]?.[1]?.routing_code_value_1_swift;
      !!payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1_SWIFT] && delete payload[BENE_FORM_KEYS.ROUTING_CODE_VALUE_1_SWIFT];
    }
  } else {
    payload[BENE_FORM_KEYS.PAYOUT_METHOD] = PaymentMethod.CRYPTO;

    if (formData?.[BENE_FORM_STEPS.STEP_1]?.[0]?.beneficiary_bank_name?.value === BANK_TYPES_FOR_RFI.OTHERS)
      payload[BENE_FORM_KEYS.BENEFICIARY_BANK_TYPE] = BANK_TYPES_FOR_RFI.OTHERS;
    else payload[BENE_FORM_KEYS.BENEFICIARY_BANK_TYPE] = BANK_TYPES_FOR_RFI.VASP;
  }

  payload[BENE_FORM_KEYS.BENEFICIARY_COUNTRY_CODE] = formData?.[BENE_FORM_STEPS.STEP_2]?.[1]?.country?.value;
  payload[BENE_FORM_KEYS.BENEFICIARY_STATE] = formData?.[BENE_FORM_STEPS.STEP_2]?.[1]?.state?.value;
  payload[BENE_FORM_KEYS.BENEFICIARY_BANK_COUNTRY_CODE] = formData?.[BENE_FORM_STEPS.STEP_3]?.[2]?.country?.value;
  payload[BENE_FORM_KEYS.DESTINATION_COUNTRY] = formData?.[BENE_FORM_STEPS.STEP_3]?.[2]?.country?.value;
  payload[BENE_FORM_KEYS.BENEFICIARY_BANK_STATE] = formData?.[BENE_FORM_STEPS.STEP_3]?.[2]?.state?.value;
  payload[BENE_FORM_KEYS.CORRESPONDANT_BANK_COUNTRY_CODE] = formData?.[BENE_FORM_STEPS.STEP_3]?.[4]?.country?.value;
  payload[BENE_FORM_KEYS.CORRESPONDANT_BANK_STATE] = formData?.[BENE_FORM_STEPS.STEP_3]?.[4]?.state?.value;
  payload[BENE_FORM_KEYS.IS_CORRESPONDANT_BANK_DETAILS_AVAILABLE] =
    payload[BENE_FORM_KEYS.IS_CORRESPONDANT_BANK_DETAILS_AVAILABLE] === 'true';

  if (formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_contact_number) {
    const beneContactNumber = JSON.parse(formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_contact_number);

    payload[BENE_FORM_KEYS.BENEFICIARY_CONTACT_NUMBER] = beneContactNumber?.phone;
    payload[BENE_FORM_KEYS.BENEFICIARY_CONTACT_COUNTRY_CODE] = beneContactNumber?.dial_code;
  }

  if (
    formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_account_type?.value === BENE_FORM_KEYS.INDIVIDUAL &&
    formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_first_name &&
    formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_last_name
  ) {
    payload[BENE_FORM_KEYS.BENEFICIARY_NAME] =
      formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_first_name +
      ' ' +
      formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_last_name;

    !!payload[BENE_FORM_KEYS.BENEFICIARY_FIRST_NAME] && delete payload[BENE_FORM_KEYS.BENEFICIARY_FIRST_NAME];
    !!payload[BENE_FORM_KEYS.BENEFICIARY_LAST_NAME] && delete payload[BENE_FORM_KEYS.BENEFICIARY_LAST_NAME];
  }

  !!payload[BENE_FORM_KEYS.COUNTRY] && delete payload[BENE_FORM_KEYS.COUNTRY];
  !!payload[BENE_FORM_KEYS.STATE] && delete payload[BENE_FORM_KEYS.STATE];

  return payload;
};

export const getRoutingCodeDisplayName = (routingCode: string, currency: string): string => {
  if (currency === BENE_FORM_KEYS.EUR && routingCode === BENE_FORM_KEYS.SWIFT)
    return ROUTING_CODE_DISPLAY_VALUE[BENE_FORM_KEYS.SEPA];
  else return ROUTING_CODE_DISPLAY_VALUE[routingCode];
};

export const createPrefillDataFormat = (prefillData: MapAny, isContextualData?: boolean): MapAny => {
  const prefillDataFormat: MapAny = { ...prefillData };

  if (prefillData?.beneficiary_account_type) {
    prefillDataFormat[BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_TYPE] = JSON.stringify({
      label: prefillData?.beneficiary_account_type,
      value: prefillData?.beneficiary_account_type,
    });
  }
  if (isContextualData) {
    prefillDataFormat[BENE_FORM_KEYS.BENEFICIARY_CONTACT_NUMBER] = prefillData?.beneficiary_contact_number;
  } else {
    if (prefillData?.beneficiary_contact_country_code && prefillData?.beneficiary_contact_number) {
      prefillDataFormat[BENE_FORM_KEYS.BENEFICIARY_CONTACT_NUMBER] = JSON.stringify({
        phone: prefillData?.beneficiary_contact_number,
        dial_code: prefillData?.beneficiary_contact_country_code,
      });
    }
    if (prefillData?.beneficiary_contact_country_code || prefillData?.beneficiary_contact_number) {
      prefillDataFormat[BENE_FORM_KEYS.BENEFICIARY_CONTACT_NUMBER] = JSON.stringify({
        phone: prefillData?.beneficiary_contact_number,
        dial_code: prefillData?.beneficiary_contact_country_code?.startsWith('+')
          ? prefillData?.beneficiary_contact_country_code
          : `+${prefillData?.beneficiary_contact_country_code}`,
      });
    }
  }
  if (prefillData?.beneficiary_country_code) {
    const selectedCountry = COUNTRY_OPTIONS.find((country) => country.code === prefillData?.beneficiary_country_code);

    prefillDataFormat[BENE_FORM_KEYS.COUNTRY] = JSON.stringify({
      label: selectedCountry?.name,
      value: selectedCountry?.code,
      icon: null,
      isDisabled: false,
      iconCategory: '',
      iconId: '',
      info: '',
    });
  }
  if (prefillData?.beneficiary_relationship) {
    prefillDataFormat[BENE_FORM_KEYS.BENEFICIARY_RELATIONSHIP] = JSON.stringify({
      label: prefillData?.beneficiary_relationship,
      value: prefillData?.beneficiary_relationship,
    });
  }

  if (prefillData?.beneficiary_state) {
    if (isContextualData)
      prefillDataFormat[BENE_FORM_KEYS.STATE] = JSON.stringify({
        label: prefillData?.beneficiary_state,
        value: prefillData?.beneficiary_state,
      });
    else prefillDataFormat[BENE_FORM_KEYS.STATE] = prefillData?.beneficiary_state;
  }

  return prefillDataFormat;
};

export const formatUpdateCounterPartyPayload = (
  formData: Record<BENE_FORM_STEPS, MapAny[]>,
  eligibiltyData?: CounterPartyEligibility
): CounterPartyUpdatePayloadData => {
  if (!eligibiltyData) return {};

  const payload: CounterPartyUpdatePayloadData = {};

  const updateAllowedKeys = Object.keys(eligibiltyData).filter(
    (key) => !eligibiltyData[key as keyof CounterPartyEligibility] && key !== BENE_FORM_KEYS.IS_COUNTER_PARTY_ELIGIBLE
  );

  let beneContactNumber;

  updateAllowedKeys?.forEach((key) => {
    switch (key) {
      case BENE_FORM_KEYS.BENEFICIARY_ACCOUNT_TYPE:
        payload[BENE_FORM_KEYS.TYPE as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.[key]?.value;
        break;
      case BENE_FORM_KEYS.BENEFICIARY_ADDRESS:
        payload[BENE_FORM_KEYS.ADDRESS as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[1]?.[key];
        break;
      case BENE_FORM_KEYS.BENEFICIARY_CITY:
        payload[BENE_FORM_KEYS.CITY as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[1]?.[key];
        break;
      case BENE_FORM_KEYS.BENEFICIARY_COUNTRY_CODE:
        payload[BENE_FORM_KEYS.COUNTRY_CODE as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[1]?.[BENE_FORM_KEYS.COUNTRY]?.value;
        break;
      case BENE_FORM_KEYS.BENEFICIARY_EMAIL:
        payload[BENE_FORM_KEYS.EMAIL as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.[key];
        break;
      case BENE_FORM_KEYS.BENEFICIARY_NAME:
        payload[BENE_FORM_KEYS.NAME as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.[key];
        break;
      case BENE_FORM_KEYS.BENEFICIARY_POSTCODE:
        payload[BENE_FORM_KEYS.POSTCODE as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[1]?.[key];
        break;
      case BENE_FORM_KEYS.BENEFICIARY_RELATIONSHIP:
        payload[BENE_FORM_KEYS.RELATIONSHIP as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.[key]?.value;
        break;
      case BENE_FORM_KEYS.BENEFICIARY_STATE:
        payload[BENE_FORM_KEYS.STATE as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[1]?.[BENE_FORM_KEYS.STATE]?.value;
        break;
      case BENE_FORM_KEYS.COUNTER_PARTY_ALIAS:
        payload[BENE_FORM_KEYS.NICK_NAME as keyof CounterPartyUpdatePayloadData] =
          formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.[key];
        break;
      case BENE_FORM_KEYS.BENEFICIARY_CONTACT_COUNTRY_CODE:
      case BENE_FORM_KEYS.BENEFICIARY_CONTACT_NUMBER:
        beneContactNumber = JSON.parse(formData?.[BENE_FORM_STEPS.STEP_2]?.[0]?.beneficiary_contact_number);

        payload[BENE_FORM_KEYS.CONTACT_NUMBER as keyof CounterPartyUpdatePayloadData] = beneContactNumber?.phone;
        payload[BENE_FORM_KEYS.CONTACT_COUNTRY_CODE as keyof CounterPartyUpdatePayloadData] =
          beneContactNumber?.dial_code;
        break;
    }
  });

  return payload;
};

export const updateFormConfig = (data?: BeneForm, prefilledData?: CounterPartyEligibility) => {
  if (!data?.form?.stepper_form_sections?.length || !prefilledData) return data?.form?.stepper_form_sections;
  const updatedData: MapAny[] = [];

  data?.form?.stepper_form_sections?.forEach((step) => {
    const updatedStep: MapAny = { ...step, form_fields: [] };

    step?.form_fields?.forEach((field: MapAny) => {
      if (
        field.key === BENE_FORM_KEYS.BENEFICIARY_CONTACT_NUMBER &&
        prefilledData[BENE_FORM_KEYS.BENEFICIARY_CONTACT_NUMBER as keyof CounterPartyEligibility] &&
        prefilledData[BENE_FORM_KEYS.BENEFICIARY_CONTACT_COUNTRY_CODE as keyof CounterPartyEligibility]
      ) {
        updatedStep.form_fields.push({ ...field, type: 'hidden' });
      } else if (
        (prefilledData[field.key as keyof CounterPartyEligibility] &&
          field.key !== BENE_FORM_KEYS.BENEFICIARY_CONTACT_NUMBER) ||
        (field.key === BENE_FORM_KEYS.COUNTRY &&
          prefilledData[BENE_FORM_KEYS.BENEFICIARY_COUNTRY_CODE as keyof CounterPartyEligibility])
      ) {
        updatedStep.form_fields.push({ ...field, type: 'hidden' });
      } else if (
        field.key === BENE_FORM_KEYS.STATE &&
        prefilledData[BENE_FORM_KEYS.BENEFICIARY_STATE as keyof CounterPartyEligibility]
      ) {
        updatedStep.form_fields.push({ ...field, type: 'hidden' });
      } else updatedStep.form_fields.push(field);
    });

    if (updatedStep?.form_fields?.filter((field: MapAny) => field.type !== 'hidden')?.length > 0)
      updatedData.push(updatedStep);
    else updatedData.push({ form_fields: [], key: step.key });
  });

  return updatedData;
};

export const checkIfErrFieldIsActive = (formStructure: MapAny[], allValues: MapAny[], key: string): boolean => {
  let field;
  let data: MapAny = {};

  for (let i = 0; i < formStructure?.length; i++) {
    field = formStructure[i]?.form_fields?.find((field: FieldType) => field.key === key);
    if (field) break;
  }

  if (!field) return false;

  for (let i = 0; i < allValues?.length; i++) {
    if (Object.keys(allValues[i])?.findIndex((k) => k === key) > -1) {
      data = allValues[i];
      break;
    }
  }

  return checkActiveIfValid(field, data, allValues);
};

export const getBeneListAsOptions = (fiatBenes: Beneficiary[], cryptoBenes: Beneficiary[]): OptionsType[] => {
  const list: OptionsType[] = [];

  fiatBenes?.forEach((bene) => {
    if (list?.findIndex((item) => item?.value === bene?.destination_currency) === -1) {
      list.push({
        value: bene?.destination_currency,
        icon: (
          <SvgSpriteLoader
            id={bene?.destination_currency}
            iconCategory={ICON_SPRITE_TYPES.FIAT_CURRENCIES}
            width={24}
            height={24}
          />
        ),
      });
    }
  });

  cryptoBenes?.forEach((bene) => {
    if (list?.findIndex((item) => item?.value === bene?.routing_code_value_1) === -1) {
      list.push({
        value: bene?.routing_code_value_1,
        icon: <Wallet wallet={bene?.routing_code_value_1} hideWalletName />,
      });
    }
  });

  return list;
};

export const disabledPrefilledFields = (data?: BeneForm, prefilledData?: MapAny) => {
  if (!data?.form?.stepper_form_sections?.length || !prefilledData) return data?.form?.stepper_form_sections;
  const updatedData: MapAny[] = [];

  data?.form?.stepper_form_sections?.forEach((step) => {
    const updatedStep: MapAny = { ...step, form_fields: [] };

    step?.form_fields?.forEach((field: MapAny) => {
      if (prefilledData[field.key]) updatedStep.form_fields.push({ ...field, is_disabled: true });
      else if (field.key === COUNTRY_KEY && prefilledData[BENE_FORM_KEYS.BENEFICIARY_COUNTRY_CODE])
        updatedStep.form_fields.push({ ...field, is_disabled: true });
      else if (field.key === STATE_KEY && prefilledData[BENE_FORM_KEYS.BENEFICIARY_STATE])
        updatedStep.form_fields.push({ ...field, is_disabled: true });
      else updatedStep.form_fields.push(field);
    });

    updatedData.push(updatedStep);
  });

  return updatedData;
};
