import { PaymentType } from 'constants/index';
import { MapAny } from 'destiny/dist/types';
import { IdempotencyHeaderType } from 'types/api';
import { BeneficiaryType } from 'types/beneficiary';
import { PaymentMethod } from 'types/transactions';

export interface CounterParty {
  id: string;
  name: string;
  email: string;
  fiat_accounts_count: 0;
  crypto_wallets_count: 0;
  alias: string;
  type: string;
}

export interface CounterPartyListingParams {
  page: number;
  limit: number;
  search_key?: string;
}

export interface Beneficiary extends BeneficiaryType {
  id: string;
  beneficiary_name: string;
  beneficiary_account_type: string;
  destination_currency: string;
  beneficiary_account_number: string;
  beneficiary_alias: string;
  routing_code_type_1: string; // 'SWIFT' / ACH / Fedwire;
  routing_code_value_1: string;
  counter_party_id?: string;
  payout_method: PaymentMethod;
  counter_party?: CounterParty;
}

export interface CounterPartyById extends CounterParty {
  fiat_beneficiaries: Beneficiary[];
  crypto_beneficiaries: Beneficiary[];
}

export interface CounterPartyEditPayloadData {
  email: string;
  name: string;
  alias: string;
}

export interface CounterPartyEditPayload extends IdempotencyHeaderType {
  id: string;
  data: CounterPartyEditPayloadData;
}
export interface BeneForm {
  form: { stepper_form_sections: MapAny[]; contextual_data: MapAny };
}

export interface CreateCounterPartyPayload extends IdempotencyHeaderType {
  data: MapAny;
  counterPartyId?: string;
}

export enum CURRENCY_TYPE {
  FIAT = 'Fiat',
  CRYPTO = 'Crypto',
}

export interface DeleteCounterPartyPayload extends IdempotencyHeaderType {
  id: string;
}

export interface CounterPartyUpdatePayloadData {
  nick_name?: string;
  email?: string;
  name?: string;
  type?: string;
  relationship?: string;
  country_code?: string;
  address?: string;
  city?: string;
  state?: string;
  postcode?: string;
  contact_number?: string;
  contact_country_code?: string;
}

export interface CounterPartyUpdatePayload extends IdempotencyHeaderType {
  id: string;
  data: CounterPartyUpdatePayloadData;
}

export interface CounterPartyEligibility {
  beneficiary_account_type: string;
  beneficiary_address: string;
  beneficiary_city: string;
  beneficiary_contact_country_code: string;
  beneficiary_contact_number: string;
  beneficiary_country_code: string;
  beneficiary_email: string;
  beneficiary_name: string;
  beneficiary_postcode: string;
  beneficiary_relationship: string;
  beneficiary_state: string;
  counter_party_alias: string;
  is_counter_party_eligible: boolean;
}

export interface CounterPartyEligibilityForDestCurrencyPayload {
  id: string;
  data: {
    destination_currency?: string;
    destination_currency_type: PaymentType;
  };
}
