import { FC, useEffect, useState } from 'react';
import { useLazyGetVaultsForPayoutsV1Query } from 'api/moveMoney';
import { ACCESS_FLAGS } from 'constants/config';
import { ADD_WHITE, VAULT } from 'constants/icons';
import { NO_ACCOUNTS } from 'constants/lottie/no_accounts';
import { defaultFn } from 'destiny/dist/constants';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { useAppSelector } from 'hooks/toolkit';
import useConfiguration, { CONFIG_DEFINITIONS } from 'hooks/useConfiguration';
import AddVault from 'modules/payments-vaults/addVault';
import SelectedAccounts from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccounts';
import VaultCard from 'modules/single-transfer/thirdPartyPayouts/steps/common/VaultCard';
import SelectSourceAccountSkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/SelectSourceAccountSkeleton';
import Image from 'next/image';
import { RootState } from 'store';
import { ErrorCardTypes } from 'components/banners/types';
import ZeroListData from 'components/banners/ZeroListData';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface SelectSourceAccountProps {
  selectedRecipientAccount?: any;
  selectedSourceAccount?: any;
  onSourceAccountSelect?: (data?: any) => void;
}

const SelectSourceAccount: FC<SelectSourceAccountProps> = ({
  selectedRecipientAccount = null,
  selectedSourceAccount = null,
  onSourceAccountSelect = defaultFn,
}) => {
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isBizBankingEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_BUSINESS_BANKING_ENABLED];

  const [showAddVault, setShowAddVault] = useState(false);
  const [addVaultLoading, setAddVaultLoading] = useState(false);
  const [getVaultsForPayouts, { data: vaults, isFetching, isError }] = useLazyGetVaultsForPayoutsV1Query();
  const { checkConfigEnabled } = useConfiguration();

  const defaultSelectedSourceAccount = selectedSourceAccount ?? vaults?.[0];

  const handleGetVaultsForPayouts = () => {
    if (selectedRecipientAccount?.id) getVaultsForPayouts({ recipientId: selectedRecipientAccount?.id });
  };

  useEffect(() => {
    handleGetVaultsForPayouts();
  }, [selectedRecipientAccount]);

  useEffect(() => {
    if (vaults?.length === 1) {
      onSourceAccountSelect(vaults?.[0]);
    }
  }, [vaults]);

  const handleShowAddVault = () => setShowAddVault((prevValue) => !prevValue);

  return (
    <>
      <CommonWrapper
        isLoading={isFetching}
        skeletonType={SkeletonTypes.CUSTOM}
        skeleton={<SelectSourceAccountSkeleton />}
        isError={isError}
        errorCardType={ErrorCardTypes.API_FAIL}
        refetchFunnction={handleGetVaultsForPayouts}
        isNoData={!isFetching && vaults?.length === 0}
        noDataBanner={
          <>
            <SelectedAccounts
              sourceAccount
              sourceProps={{
                cardProps: {
                  title: 'No accounts found',
                  iconPath: VAULT, //TODO: add icon disabled,
                  iconClassName: 'tw-mr-4 tw-grayscale',
                  titleClassName: '!tw-text-TEXT_TERTIARY !f-14-400',
                },
                cardWrapperStyleClassName: '!tw-pointer-events-none',
              }}
            />
            <div className='tw-border tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-w-full tw-h-[318px]'>
              <ZeroListData
                handleClick={handleShowAddVault}
                title='No Accounts added'
                subtitle='Add account and start transacting with Zamp'
                preKybSubtitle='Activate your account to start transacting with Zamp'
                buttonText='Add Account'
                lottieJson={NO_ACCOUNTS}
                lottieDimensions={{ width: '168px', height: '120px' }}
                destinyButtonProps={{
                  buttonProps: {
                    btnType: BUTTON_TYPES.PRIMARY,
                    size: BUTTON_SIZE_TYPES.SMALL,
                    onClick: handleShowAddVault,
                    wrapperClass: 'tw-min-w-[172px] tw-mt-4',
                    isLoading: addVaultLoading,
                  },
                  customLeadingIcon: <Image src={ADD_WHITE} alt='add' width={20} height={20} />,
                  showLeadingIcon: true,
                }}
                showButton={checkConfigEnabled(CONFIG_DEFINITIONS.ADD_ACCOUNT) && !isBizBankingEnabled}
                wrapperClassName='tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-[318px] tw-gap-6'
                contentClassName='tw-gap-2 tw-w-[234px]'
                titleClassName='f-14-400 tw-text-TEXT_GRAY_4'
                subtitleClassName='f-14-300 tw-text-TEXT_GRAY_4 tw-text-center'
                id='SINGLE_TRANSFER_SOURCE_ACCOUNT'
              />
            </div>
          </>
        }
      >
        <SelectedAccounts
          sourceAccount={defaultSelectedSourceAccount}
          onSourceClick={() => onSourceAccountSelect(defaultSelectedSourceAccount)}
        />

        <div className='tw-border tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-overflow-y-auto tw-w-full tw-max-h-[259px]'>
          {vaults?.map((vault, index) => {
            return <VaultCard vault={vault} key={index} onClick={onSourceAccountSelect} />;
          })}
        </div>
      </CommonWrapper>

      {showAddVault && (
        <AddVault isOpen={showAddVault} handleVisibility={handleShowAddVault} setParentLoading={setAddVaultLoading} />
      )}
    </>
  );
};

export default SelectSourceAccount;
