import { type FC, useState } from 'react';
import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { MenuItem } from 'destiny/dist/types';
import useUserAccessFromPermission from 'hooks/useUserAccessFromPermission';
import { BANK_ACCOUNT_ACTIONS } from 'modules/beneficiary-v2/beneficiary.constants';
import { beneficiaryActions, useBeneficiaryContextStore } from 'modules/beneficiary-v2/beneficiary.context';
import BeneficiaryBankAccountWalletDeletePopup from 'modules/beneficiary-v2/BeneficiaryBankAccountWalletDeletePopup';
import BeneficiaryBankAccountWalletEdit from 'modules/beneficiary-v2/BeneficiaryBankAccountWalletEdit';
import { ActionTypes } from 'types/beneficiary';
import { Beneficiary } from 'types/beneficiary-v2';
import OptionDropdown from 'components/dropdown/OptionDropdown';

type BeneficiaryBankAccountWalletActionColumnProps = {
  data: Beneficiary;
};

const BeneficiaryBankAccountWalletActionColumn: FC<BeneficiaryBankAccountWalletActionColumnProps> = ({ data }) => {
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showEditSidebar, setShowEditSidebar] = useState<boolean>(false);

  const { dispatch } = useBeneficiaryContextStore();

  const handleAction = (action: MenuItem) => {
    switch (action.value) {
      case ActionTypes.DELETE:
        setShowDeletePopup(true);
        break;
      case ActionTypes.SEND_MONEY:
        dispatch({
          type: beneficiaryActions.SET_SHOW_MOVE_MONEY,
          payload: { showMoveMoney: true, selectedBeneficiary: data, selectedCounterParty: data?.counter_party },
        });
        break;
      case ActionTypes.EDIT:
        setShowEditSidebar(true);
        break;
      default:
        return;
    }
  };

  const hasManageRecepientAccess = useUserAccessFromPermission(
    FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId,
    FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope
  );
  const hasSendMoneyAccess = useUserAccessFromPermission(
    FLOW_PERMISSION_PROPERTIES.SEND_MONEY.permissionId,
    FLOW_PERMISSION_PROPERTIES.SEND_MONEY.scope
  );

  return (
    <div className='tw-flex tw-justify-end'>
      {(hasManageRecepientAccess || hasSendMoneyAccess) && (
        <OptionDropdown
          id={`list-options-COUNTER_PARTIES_TABLE-${data.id}`}
          className='tw-pr-1'
          options={BANK_ACCOUNT_ACTIONS}
          onMenuItemClick={handleAction}
          optionClassName='!tw-w-full'
          eventParentId={data.id}
        />
      )}
      {showDeletePopup && (
        <BeneficiaryBankAccountWalletDeletePopup
          isOpen={showDeletePopup}
          onClose={() => setShowDeletePopup(false)}
          data={data}
        />
      )}
      {showEditSidebar && (
        <BeneficiaryBankAccountWalletEdit
          isOpen={showEditSidebar}
          onClose={() => setShowEditSidebar(false)}
          data={data}
        />
      )}
    </div>
  );
};

export default BeneficiaryBankAccountWalletActionColumn;
