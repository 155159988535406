import { FC, useEffect, useMemo, useState } from 'react';
import { useLazyGetVaultsForPayoutsQuery } from 'api/moveMoney';
import { ACCESS_FLAGS } from 'constants/config';
import { ADD_WHITE, VAULT } from 'constants/icons';
import { NO_ACCOUNTS } from 'constants/lottie/no_accounts';
import { defaultFn } from 'destiny/dist/constants';
import { BUTTON_SIZE_TYPES, BUTTON_TYPES } from 'destiny/dist/constants/molecules/buttons';
import { useAppSelector } from 'hooks/toolkit';
import useConfiguration, { CONFIG_DEFINITIONS } from 'hooks/useConfiguration';
import { CUSTOMER_PROFILE } from 'modules/accounts-mea/accounts.constants';
import AddVault from 'modules/payments-vaults/addVault';
import SelectedAccounts from 'modules/single-transfer/thirdPartyPayouts/steps/common/SelectedAccounts';
import AccountsByEntitySelector from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/accountsByEntitySelector/AccountsByEntitySelector';
import AccountsByVaultsByEntitySelector from 'modules/single-transfer/thirdPartyPayouts/steps/selectSourceAccount/accountsByVaultsByEntitySelector/AccountsByVaultsByEntitySelector';
import SelectSourceAccountSkeleton from 'modules/single-transfer/thirdPartyPayouts/steps/skeleton/SelectSourceAccountSkeleton';
import Image from 'next/image';
import { RootState } from 'store';
import { AccountByVaultIdType, VaultsForPayoutsResponseType } from 'types/accountsApi.types';
import { ErrorCardTypes } from 'components/banners/types';
import ZeroListData from 'components/banners/ZeroListData';
import { SkeletonTypes } from 'components/skeletons/types';
import CommonWrapper from 'components/wrappers/CommonWrapper';

interface SelectSourceAccountProps {
  selectedRecipientAccount?: any;
  selectedSourceAccount?: any;
  selectedSourceAccountData?: {
    entityId: string;
    vaultId: string;
    accountId: string;
  };
  onSourceAccountSelect?: (data?: any) => void;
  controlClassName?: string;
  filterSourceAccounts?: (payload?: VaultsForPayoutsResponseType) => VaultsForPayoutsResponseType;
  skeletonClassName?: string;
}

const SelectSourceAccount: FC<SelectSourceAccountProps> = ({
  selectedRecipientAccount = null,
  selectedSourceAccount = null,
  selectedSourceAccountData: { entityId = '', vaultId = '', accountId = '' } = {},
  onSourceAccountSelect = defaultFn,
  controlClassName = '',
  filterSourceAccounts,
  skeletonClassName = '',
}) => {
  const { userAccessFlags } = useAppSelector((state: RootState) => state.user);
  const isBizBankingEnabled = userAccessFlags?.[ACCESS_FLAGS.IS_BUSINESS_BANKING_ENABLED];

  const [showAddVault, setShowAddVault] = useState(false);
  const [addVaultLoading, setAddVaultLoading] = useState(false);
  const [showAccountsByVaultsByEntitySelector, setShowAccountsByVaultsByEntitySelector] = useState(false);

  const [getVaultsForPayouts, { data: sourceAccountData, isFetching, isError }] = useLazyGetVaultsForPayoutsQuery();
  const { checkConfigEnabled } = useConfiguration();

  const sourceAccounts = useMemo(
    () => (filterSourceAccounts ? filterSourceAccounts(sourceAccountData) : sourceAccountData),
    [sourceAccountData]
  );

  const { customer_profile, entities } = sourceAccounts ?? {}; // filter
  const isLargeMerchant = customer_profile === CUSTOMER_PROFILE.LARGE_MERCHANT;

  const handleGetVaultsForPayouts = () => {
    if (selectedRecipientAccount?.id) getVaultsForPayouts({ recipientId: selectedRecipientAccount?.id });
  };

  useEffect(() => {
    handleGetVaultsForPayouts();
  }, [selectedRecipientAccount]);

  const handleShowAddVault = () => setShowAddVault((prevValue) => !prevValue);

  const handleSourceAccountSelect = (accountData: AccountByVaultIdType) => onSourceAccountSelect(accountData);

  useEffect(() => {
    // TODO:refactor
    if (entities && entities?.length === 1 && !selectedSourceAccount) {
      const selectedEntity = entities?.[0];

      if (!!selectedEntity && selectedEntity?.vaults?.length === 1) {
        const selectedVault = selectedEntity?.vaults?.[0];

        if (!!selectedVault && selectedVault?.accounts?.length === 1) {
          const selectedAccount = selectedVault?.accounts?.[0];

          if (selectedAccount) {
            onSourceAccountSelect({ ...selectedAccount, parentEntity: selectedEntity });
          }
        }
      }
    }
  }, [entities]);

  useEffect(() => {
    // TODO:refactor
    if (entityId && vaultId && accountId && entities && entities?.length > 0 && !selectedSourceAccount) {
      const selectedEntity = entities?.find((entity) => entity?.id === entityId);

      if (selectedEntity) {
        const selectedVault = selectedEntity?.vaults?.find((vault) => vault?.id === vaultId);

        if (selectedVault) {
          const selectedAccount = selectedVault?.accounts?.find((account) => account?.id === accountId);

          if (selectedAccount) {
            onSourceAccountSelect({ ...selectedAccount, parentEntity: selectedEntity });
          }
        }
      }
    }
  }, [entityId, vaultId, accountId, entities]);

  const handleShowAccountsByVaultsByEntitySelector = () =>
    setShowAccountsByVaultsByEntitySelector((prevView) => !prevView);

  return (
    <>
      <CommonWrapper
        isLoading={isFetching}
        skeletonType={SkeletonTypes.CUSTOM}
        skeleton={<SelectSourceAccountSkeleton className={skeletonClassName} />}
        isError={isError}
        errorCardType={ErrorCardTypes.API_FAIL}
        refetchFunnction={handleGetVaultsForPayouts}
        isNoData={!isFetching && entities?.length === 0}
        noDataBanner={
          <>
            <SelectedAccounts
              sourceAccount
              sourceProps={{
                cardProps: {
                  title: 'No accounts found',
                  iconPath: VAULT, //TODO: add icon disabled,
                  iconClassName: 'tw-mr-4 tw-grayscale',
                  titleClassName: '!tw-text-TEXT_TERTIARY !f-14-400',
                },
                cardWrapperStyleClassName: '!tw-pointer-events-none',
              }}
            />
            <div className='tw-border tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-w-full tw-h-[318px]'>
              <ZeroListData
                handleClick={handleShowAddVault}
                title=''
                subtitle="The selected beneficiary's corridor isn't supported by the accounts you have access to.
                Please select a different beneficiary to continue"
                preKybSubtitle='Activate your account to start transacting with Zamp'
                buttonText='Add Account'
                lottieJson={NO_ACCOUNTS}
                lottieDimensions={{ width: '168px', height: '120px' }}
                destinyButtonProps={{
                  buttonProps: {
                    btnType: BUTTON_TYPES.PRIMARY,
                    size: BUTTON_SIZE_TYPES.SMALL,
                    onClick: handleShowAddVault,
                    wrapperClass: 'tw-min-w-[172px] tw-mt-4',
                    isLoading: addVaultLoading,
                  },
                  customLeadingIcon: <Image src={ADD_WHITE} alt='add' width={20} height={20} />,
                  showLeadingIcon: true,
                }}
                showButton={checkConfigEnabled(CONFIG_DEFINITIONS.ADD_ACCOUNT) && !isBizBankingEnabled}
                wrapperClassName='tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-[318px] tw-gap-6'
                contentClassName='tw-gap-2 tw-w-[234px]'
                titleClassName='f-14-400 tw-text-TEXT_GRAY_4'
                subtitleClassName='f-14-300 tw-text-TEXT_GRAY_4 tw-text-center'
                id='SINGLE_TRANSFER_SOURCE_ACCOUNT'
              />
            </div>
          </>
        }
      >
        <SelectedAccounts
          sourceAccount
          sourceProps={{
            cardProps: {
              title: 'Select Source Account',
              titleClassName: '!tw-text-TEXT_PRIMARY !f-14-400',
              showIcon: false,
              onClick: isLargeMerchant ? handleShowAccountsByVaultsByEntitySelector : undefined,
            },
            cardWrapperStyleClassName: isLargeMerchant ? '' : `!tw-pointer-events-none ${controlClassName}`,
          }}
        />
        {entities &&
          (isLargeMerchant ? (
            showAccountsByVaultsByEntitySelector && (
              <AccountsByVaultsByEntitySelector
                entities={entities}
                onAccountSelect={handleSourceAccountSelect}
                onClose={handleShowAccountsByVaultsByEntitySelector}
              />
            )
          ) : (
            <div className='tw-border tw-border-DIVIDER_GRAY tw-rounded-2.5 tw-shadow-menu tw-overflow-y-auto tw-w-full tw-max-h-[260px] tw-bg-white'>
              {entities?.map((entity) => (
                <AccountsByEntitySelector
                  entity={entity}
                  key={entity?.id}
                  defaultOpen
                  onAccountSelect={handleSourceAccountSelect}
                />
              ))}
            </div>
          ))}
      </CommonWrapper>

      {showAddVault && (
        <AddVault isOpen={showAddVault} handleVisibility={handleShowAddVault} setParentLoading={setAddVaultLoading} />
      )}
    </>
  );
};

export default SelectSourceAccount;
