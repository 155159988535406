import { type FC, useEffect } from 'react';
import { MapAny } from 'destiny/dist/types';
import {
  BENE_FORM_STEPS,
  beneficiaryActions,
  defaultConfig,
  defaultError,
  useBeneficiaryContextStore,
} from 'modules/beneficiary-v2/beneficiary.context';
import BeneficiaryFormSection from 'modules/beneficiary-v2/BeneficiaryFormSection';
import { checkIsObjectEmpty } from 'utils/common';
import { constructFormStateData } from 'utils/kyb';

type BeneficiaryFormWrapperProps = {
  data?: MapAny[];
  hideSectionPart?: boolean;
  prefilledData?: MapAny;
  noDefaultValueFields?: string[];
};

const BeneficiaryFormWrapper: FC<BeneficiaryFormWrapperProps> = ({
  data,
  hideSectionPart,
  prefilledData = {},
  noDefaultValueFields = [],
}) => {
  const {
    state: { formStateData = defaultConfig, formErrors = defaultError, formConfig, currentStep },
    dispatch,
  } = useBeneficiaryContextStore();

  useEffect(() => {
    if (data?.length) {
      dispatch({
        type: beneficiaryActions.SET_FORM_CONFIG,
        payload: { formConfig: { ...formConfig, [currentStep]: data } },
      });

      if (formStateData[currentStep]?.length && checkIsObjectEmpty(prefilledData)) return;

      const currentFormStateData = constructFormStateData(data, prefilledData, noDefaultValueFields);

      dispatch({
        type: beneficiaryActions.SET_FORM_STATE_DATA,
        payload: { formStateData: { ...formStateData, [currentStep]: currentFormStateData } },
      });
    }
  }, [data]);

  const handleChange = (key: string, value: any, index: number) => {
    if (formErrors?.[currentStep]?.[key]) {
      const tempErrs = { ...formErrors?.[currentStep], [key]: false };

      dispatch({
        type: beneficiaryActions.SET_FORM_ERRORS,
        payload: { formErrors: { ...formErrors, [currentStep]: tempErrs } },
      });
    }

    const updatedFormStateData = [...formStateData[currentStep]];

    updatedFormStateData[index] = { ...updatedFormStateData[index], [key]: value };

    if (key === 'country') {
      updatedFormStateData[index] = { ...updatedFormStateData[index], state: null };
    }

    if (
      key === 'routing_code_type_1' &&
      value?.value === 'FX_EXCHANGE' &&
      !checkIsObjectEmpty(updatedFormStateData[3])
    ) {
      updatedFormStateData[3] = {
        ...updatedFormStateData[3],
        ['is_correspondent_bank_details_available']: 'false',
      };
    }

    const step3Data = currentStep === BENE_FORM_STEPS.STEP_1 ? [] : formStateData[BENE_FORM_STEPS.STEP_3];
    const step2Data = currentStep === BENE_FORM_STEPS.STEP_1 ? [] : formStateData[BENE_FORM_STEPS.STEP_2];

    dispatch({
      type: beneficiaryActions.SET_FORM_STATE_DATA,
      payload: {
        formStateData: {
          ...formStateData,
          [BENE_FORM_STEPS.STEP_2]: step2Data,
          [BENE_FORM_STEPS.STEP_3]: step3Data,
          [currentStep]: updatedFormStateData,
        },
      },
    });
  };

  return (
    <div className='tw-space-y-4 tw-relative'>
      {formStateData?.[currentStep]?.map((item, index) => (
        <BeneficiaryFormSection
          formStateData={item}
          key={data?.[index]?.key}
          formConfig={data?.[index] ?? {}}
          onChange={(key, value) => handleChange(key, value, index)}
          errors={formErrors?.[currentStep]}
          hideSectionPart={hideSectionPart || 'correspondent_bank_details_checkbox' === data?.[index]?.key}
          allValues={formStateData?.[currentStep]}
        />
      ))}
    </div>
  );
};

export default BeneficiaryFormWrapper;
